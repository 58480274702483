<template>
  <section class="d-flex flex-row align-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          depressed
          large
          dark
          elevation="2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left> mdi-upload</v-icon>
          {{ $t('RATIO.UPLOAD_STATISTICS') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('RATIO.UPLOAD_STATISTICS') }}
        </v-card-title>

        <v-card-text>
          <vue-dropzone
            ref="statisticsUpload"
            id="statisticsUpload"
            class="mt-6 d-flex justify-center flex-column align-center"
            :options="dropzoneOptions"
            @vdropzone-sending="fileSending"
            @vdropzone-file-added="fileAdded"
            @vdropzone-success="closeDialog"
          />
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{ $t('COMMON.CANCEL') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      color="#e0bc37"
      depressed
      dark
      large
      elevation="2"
      class="ml-4"
      @click="download"
    >
      <v-icon left> mdi-download</v-icon>
      {{ $t('RATIO.DOWNLOAD_TEMPLATE') }}
    </v-btn>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import params from '@/env.params.js'

export default {
  name: 'StatisticsManage',

  components: {
    vueDropzone: vue2Dropzone
  },

  props: {
    title: {
      type: String,
      default: 'Statistics'
    }
  },

  data: (_t) => ({
    dialog: false,
    dropzoneOptions: {
      url: `${ params.API_STATISTIC_TECH_URL }/report/upload`,
      headers: {
        'Authorization': _t.$services.getToken(),
        'Content-Type': 'application/json',
      }, // header
      method: 'put',
      thumbnailWidth: 150,
      maxFilesize: 1,
      autoProcessQueue: false
    },
    base64: '',
    delayAfterFileUpload: 2000,
  }),

  methods: {

    fileSending (file, xhr) {
      xhr.send(JSON.stringify({ data: this.base64 }))
    },

    fileAdded (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.base64 = event.target.result.split(',')[1]
        this.$refs.statisticsUpload.processQueue()
      }
      reader.readAsDataURL(file)
    },

    async download () {

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }

      try {
        const windowUrl = window.URL || window.webkitURL
        const link = document.createElement('a')
        const response = await this.$services.api_statistic.report.download()
        const blob = new Blob([s2ab(atob(response.data))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
        });
        const url = windowUrl.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'template-stat')
        link.click()
        windowUrl.revokeObjectURL(url)
      } catch (e) {
        console.error(e)
      }
    },

    closeDialog () {
      setTimeout(() => {
        this.dialog = false
        this.$refs.statisticsUpload.removeAllFiles()
      }, this.delayAfterFileUpload)
    },
  }
}
</script>
